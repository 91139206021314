// Work.js
import React, { useState } from 'react';
import Project from './Project';
import CaseStudy from './CaseStudy';
import './Work.css';
import '../App.css';

const Work = () => {

  return (
    <div className="work">
      {/* <h2>Work</h2> */}
      <Project 
        />
    </div>
  );
};

export default Work;